import { HeadFC, Link } from "gatsby";
import * as React from "react";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col gap-8 py-6 mx-auto container-7xl">
      <div className="prose prose-slate prose-headings:text-slate-700">
        <h1>Die Seite existiert nicht.</h1>
        <p>
          Die gesuchte Seite konnte leider nicht gefunden werden. Das tut uns
          leid.
          <br />
          <br />
          Am besten gehen sie zurück zur <Link to="/">Homepage</Link> und
          navigieren von dort aus weiter.
        </p>
      </div>
    </div>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
